<template>
  <div>
    <!-- User Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Persoonsgegevens">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <label>Particulier / Zakelijk</label>
                  <div class="demo-inline-spacing mb-2">
                    <b-form-radio
                      v-model="relationData.type"
                      class="mt-0"
                      name="companyOrConsumer"
                      :value="1"
                    >
                      Particulier
                    </b-form-radio>
                    <b-form-radio
                      v-model="relationData.type"
                      class="mt-0"
                      name="companyOrConsumer"
                      :value="2"
                    >
                      Zakelijk
                    </b-form-radio>
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="gender"
                    rules="required"
                  >
                    <label>Geslacht</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="relationData.gender"
                        name="gender"
                        value="m"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Man
                      </b-form-radio>
                      <b-form-radio
                        v-model="relationData.gender"
                        name="gender"
                        value="f"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Vrouw
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <!-- Field: firstname -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="firstName"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="relationData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <!-- Field: middle name -->
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tussenvoegsel"
                    label-for="middleName"
                  >
                    <b-form-input
                      id="middleName"
                      v-model="relationData.middleName"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: lastname -->
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="lastName"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="relationData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>
              <b-row>
                <!-- Field: Phone -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="phone"
                    rules="required|integer|min:10"
                  >
                    <b-form-group
                      label="Telefoonnummer"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="relationData.phone"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="mobile"
                    rules="required|integer|min:10"
                  >
                    <b-form-group
                      label="Mobiel telefoonnummer"
                      label-for="phone"
                    >
                      <b-form-input
                        id="mobile"
                        v-model="relationData.mobile"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <!-- Field: Email -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="relationData.email"
                        type="email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: birthdate -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="birthDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Geboortedatum"
                      label-for="birthDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="birthDate"
                        v-model="relationData.birthDate"
                        :config="birthDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="maritalStatus"
                    rules="required"
                  >
                    <b-form-group
                      label="Burgelijke staat"
                      label-for="martialStatus"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="relationData.maritalStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="martialOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="active"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="relationData.type === 1"
            cols="12"
            md="6"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Identiteit">
              <b-row>
                <!-- Field: nationality -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="nationality"
                    rules="required"
                  >
                    <b-form-group
                      label="Nationaliteit"
                      label-for="nationality"
                    >
                      <b-form-input
                        id="nationality"
                        v-model="relationData.nationality"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <!-- Field: occupation -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="occupation"
                    rules="required"
                  >
                    <b-form-group
                      label="Beroep"
                      label-for="occupation"
                    >
                      <b-form-input
                        id="occupation"
                        v-model="relationData.occupation"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: birthPlace -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="birthPlace"
                    rules="required"
                  >
                    <b-form-group
                      label="Geboorteplaats"
                      label-for="birthPlace"
                    >
                      <b-form-input
                        id="birthPlace"
                        v-model="relationData.birthPlace"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ID Type -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idType"
                    rules="required"
                  >
                    <b-form-group
                      label="Indentiteits type"
                      label-for="idType"
                      :state="getValidationState(validationContext)"
                    >

                      <v-select
                        v-model="relationData.idType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="idTypeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="active"
                        :state="getValidationState(validationContext)"
                        :class="getValidationState(validationContext) ? '' : 'is-invalid'"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: idNumber -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="ID nummer"
                      label-for="idNumber"
                    >
                      <b-form-input
                        id="idNumber"
                        v-model="relationData.idNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: idIssueDate -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idIssueDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Afgiftedatum"
                      label-for="idIssueDate"
                    >
                      <flat-pickr
                        id="isIssueDate"
                        v-model="relationData.isIssueDate"
                        :config="isIssueDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: idExpiryDate -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idExpiryDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Geldig tot"
                      label-for="idExpiryDate"
                    >
                      <flat-pickr
                        id="idExpiryDate"
                        v-model="relationData.idExpiryDate"
                        :config="idExpiryDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Adresgegevens">
              <!-- Field: street -->
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="street"
                    rules="required"
                  >
                    <b-form-group
                      label="Straatnaam"
                      label-for="street"
                    >
                      <b-form-input
                        id="street"
                        v-model="relationData.street"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: housenumber -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="housenumber"
                    rules="required"
                  >
                    <b-form-group
                      label="Huisnummer"
                      label-for="housenumber"
                    >
                      <b-form-input
                        id="housenumber"
                        v-model="relationData.housenumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: postalcode -->

                <!--                <b-col-->
                <!--                    cols="12"-->
                <!--                    md="6"-->
                <!--                >-->
                <!--                  <b-form-group-->
                <!--                      label="Postcode"-->
                <!--                      label-for="postalcode"-->
                <!--                  >-->
                <!--                    <b-form-input-->
                <!--                        id="postalcode"-->
                <!--                        v-model="relationData.postalcode"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="country"
                    rules="required"
                  >
                    <b-form-group
                      label="Country"
                      label-for="country"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="relationData.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="active"
                        @change="countryChanged(relationData.country)"
                        @input="countryChanged(relationData.country)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: city -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="city"
                    rules="required"
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="relationData.city"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- District-->
                  <validation-provider
                    #default="validationContext"
                    name="district"
                    rules="required"
                  >
                    <b-form-group
                      label="District"
                      label-for="district"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="relationData.district"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="districtOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="active"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="relationData.type === 2"
            cols="12"
            md="6"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Bedrijfsgegevens">
              <!-- Field: companyName -->
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyName"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrijfsnaam"
                      label-for="street"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="relationData.companyName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: Rechtsform -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="companyLegalForm"
                    rules="required"
                  >
                    <b-form-group
                      label="Rechtsvorm"
                      label-for="companyLegalForm"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="relationData.companyLegalForm"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="legalFormOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="active"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: KKF number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyKKFNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="KKF nummer"
                      label-for="companyKKFNumber"
                    >
                      <b-form-input
                        id="companyKKFNumber"
                        v-model="relationData.companyKKFNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: companyBaseNumber -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyBaseNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="Stamnummer"
                      label-for="companyBaseNumber"
                    >
                      <b-form-input
                        id="companyBaseNumber"
                        v-model="relationData.companyBaseNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: companyTaxNumber -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyTaxNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="OBnummer"
                      label-for="companyTaxNumber"
                    >
                      <b-form-input
                        id="companyTaxNumber"
                        v-model="relationData.companyTaxNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="6 text-left"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="resetForm"
                  >
                    <span>Reset</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="6 text-right"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    <span>Opslaan</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormRadio,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import router from '@/router'
import store from '@/store'
import relationsStoreModule from '@/views/pages/relations/relationsStoreModule'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  alphaNum,
  email,
  required,
  integer,
  min,
} from '@core/utils/validations/validations'
import useRelationsList from '@/views/pages/relations/useRelationsList'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { todayMinYears } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      integer,
      required,
      alphaNum,
      email,
      min,
      birthDateConfig: {
        maxDate: todayMinYears(18),
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      isIssueDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      idExpiryDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
    }
  },
  methods: {
    countryChanged(countryCode) {
      this.relationData.district = ''
      this.districtOptions = this.resolveDistrictForCountry(countryCode)
    },
  },
  setup() {
    const blankRelationData = {
      gender: '',
      type: 1,
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      mobile: '',
      email: '',
      birthDate: '',
      nationality: '',
      occupation: '',
      birthPlace: '',
      idType: '',
      idNumber: '',
      isIssueDate: '',
      idExpiryDate: '',
      companyName: '',
      companyLegalForm: '',
      companyKKFNumber: '',
      companyBaseNumber: '',
      companyTaxNumber: '',
      houseNumber: '',
      street: '',
      country: '',
      city: '',
      district: '',
    }

    const relationData = ref(JSON.parse(JSON.stringify(blankRelationData)))
    const resetRelationData = () => {
      relationData.value = JSON.parse(JSON.stringify(blankRelationData))
    }

    const RELATION_APP_STORE_MODULE_NAME = 'apps-relations-sidebar'

    // Register module
    if (!store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.registerModule(RELATION_APP_STORE_MODULE_NAME, relationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATION_APP_STORE_MODULE_NAME)
    })

    const onSubmit = () => {
      store.dispatch('apps-relations-sidebar/addRelation', relationData.value)
        .then(() => {
          router.push({ name: 'apps-relations-list' })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRelationData)

    const {
      resolveDistrictForCountry,
      countryOptions,
      districtOptions,
      idTypeOptions,
      legalFormOptions,
      martialOptions,
    } = useRelationsList()

    return {
      relationData,
      onSubmit,
      refFormObserver,
      resolveDistrictForCountry,
      getValidationState,
      resetForm,
      countryOptions,
      districtOptions,
      idTypeOptions,
      legalFormOptions,
      todayMinYears,
      martialOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organisation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.v-select.is-invalid{
  [dir] .vs__dropdown-toggle {
    border-color: #ea5455;
    background-repeat: no-repeat;
  }
}

.invalid{
  .card-title{
    color: #ea5455
  }
}
</style>

<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
