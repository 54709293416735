import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default function useRelationsList() {
  const toast = useToast()
  const refRelationsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Relatie', key: 'firstName', sortable: false },
    { label: 'Particulier/Zakelijk', key: 'type', sortable: true },
    { label: 'Telefoonnummer', key: 'phoneNumber', sortable: true },
    { label: 'Adres', key: 'street', sortable: true },
    { label: 'Acties', key: 'actions', tdClass: 'actions' },
  ]
  const perPage = ref(10)
  const totalRelations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refRelationsListTable.value ? refRelationsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRelations.value,
    }
  })

  const refetchData = () => {
    refRelationsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchRelations = (ctx, callback) => {
    store
      .dispatch('apps-relations-list/fetchRelations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalRelations.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Relaties',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: `${errorAndSuccesDefaultMessage.fetchDataError}`,
          },
        })
      })
  }

  const resolveTypeVariant = status => {
    if (status === 1) return 'Particulier'
    if (status === 2) return 'Zakelijk'
    return ''
  }

  return {
    fetchRelations,
    tableColumns,
    perPage,
    currentPage,
    totalRelations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRelationsListTable,
    refetchData,
    resolveTypeVariant,
    showOverlay,
  }
}
